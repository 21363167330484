<template>
  <div class="custom-login-methods">
    <div class="text-center mb-2">
      <button
          class="btn w-100 waves-effect waves-light custom-login-btn button-icon"
          @click="loginWithGoogle"
      >
        <img :src="brandLogoHelper.logoGoogle()" alt="Logo Google" height="22"/>
        {{ $t("login-methods.with-google") }}
      </button>
    </div>
    <div class="text-center mb-2">
      <button
          class="btn w-100 waves-effect waves-light custom-login-btn button-icon"
          @click="checkLoginState"
      >
        <img :src="brandLogoHelper.logoFacebook()" alt="Logo Facebook" height="22"/>
        {{ $t("login-methods.with-facebook") }}
      </button>
    </div>
    <div class="text-center mb-2">
      <button
          class="btn w-100 waves-effect waves-light custom-login-btn button-icon"
          @click="loginWithApple"
      >
        <img :src="brandLogoHelper.logoApple()" alt="Logo iCloud" height="22"/>
        {{ $t("login-methods.with-icloud") }}
      </button>
    </div>

    <vue-apple-login
        ref="appleLoginComponent"
        style="display: none;">
    </vue-apple-login>
  </div>
</template>

<script>
import {brandLogoHelper} from "@/helpers/brand-logo-helper";
import Swal from "sweetalert2";

export default {
  name: "custom-login-methods",
  computed: {
    Swal() {
      return Swal
    },

    brandLogoHelper() {
      return brandLogoHelper
    }
  },

  data() {
    return {
      googleLoginWrapperButton: null,
      fb: null
    }
  },

  methods: {

    loginWithApple() {
      this.$refs.appleLoginComponent.$el.click();
    },

    loginWithGoogle() {
      this.googleLoginWrapperButton.click();
    },

    statusChangeCallback(response) {
      // console.log("wtf")
      // console.log(response)
      if (response.status === 'connected') {
        // console.log("test")
        this.facebookLogin(response.authResponse.accessToken);
      }
    },

    checkLoginState() {
      // console.log("test")
      window.FB.login(response => {   // See the onlogin handler
        // console.log("how")
        this.statusChangeCallback(response);
      }, {scope: 'public_profile,email'});
    },

    facebookLogin(accessToken) {
      const data = {accessToken: accessToken};
      const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

      fetch(`${process.env.VUE_APP_API_URL}/user/oauth2-integration/facebook?environment=${process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE}`, {
        method: 'POST', // Specify the method
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded' // Specify the content type
        },
        body: formBody // Send the URL-encoded string as the body
      }).then(response => {
        if (response.redirected) {
          window.location.href = response.url;
        }
      });
    },

    initSdk(options, locale = 'en_US') {
      // prettier-ignore
      // https://developers.facebook.com/docs/javascript/advanced-setup
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '718096683086502',
          cookie: true,                     // Enable cookies to allow the server to access the session.
          xfbml: true,                     // Parse social plugins on this webpage.
          version: 'v18.0'           // Use this Graph API version for this call.
        })
      };
      /* eslint-disable */
      // prettier-ignore
      ;(function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id
        js.src = `//connect.facebook.net/${locale}/sdk.js`
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', "facebook-jssdk"))
    }

  },

  mounted() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '718096683086502',
        cookie: true,                     // Enable cookies to allow the server to access the session.
        xfbml: true,                     // Parse social plugins on this webpage.
        version: 'v18.0'           // Use this Graph API version for this call.
      })
    };
    /* eslint-disable */
    // prettier-ignore
    ;(function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id
      js.src = `//connect.facebook.net/en_US/sdk.js`
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', "facebook-jssdk"))

    // window.fbAsyncInit = () => {
    //   this.fb = window.FB.init({
    //     appId: '718096683086502',
    //     cookie: true,                     // Enable cookies to allow the server to access the session.
    //     xfbml: true,                     // Parse social plugins on this webpage.
    //     version: 'v18.0'           // Use this Graph API version for this call.
    //   });
    // };

    if (window.google.accounts) {
      const gClientId = "134150469636-ll9f2dmboia3qo3a62bc3r5ob7ah1lbu.apps.googleusercontent.com"
      window.google.accounts.id.initialize({
        client_id: gClientId,
        context: "signup",
        ux_mode: "redirect",
        login_uri: `${process.env.VUE_APP_API_URL}/user/oauth2-integration/google?environment=${process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE}`,
        auto_prompt: false
      })

      const googleLoginWrapper = document.createElement("div");
      googleLoginWrapper.style.display = "none";
      googleLoginWrapper.classList.add("custom-google-button");

      document.body.appendChild(googleLoginWrapper);
      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      });

      this.googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");
    }
  }

}
</script>